<template>
	<div class="container">
		<top-header></top-header>
		<div class="testing">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>产品运行在线监测</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="title">
					<p>产品运行在线监测</p>
					<p>On line monitoring of product operation</p>
				</div>
				<div class="item">
					<router-link to="/operation"><img src="../../../static/images/icon/testing2.png"></router-link>
				</div>
				<div class="item">
					<router-link to="/monitor"><img src="../../../static/images/icon/testing1.png"></router-link>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.testing {
		background: url(../../../static/images/icon/testingBg.png) no-repeat 0 0;
		background-size: cover;
		margin-top: 60px;
		font-size: 16px;
		padding-top: 20px;
		min-height: 87.8vh;
		box-sizing: border-box;

		.item {
			display: inline-block;
			vertical-align: middle;
			width: 45%;
			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
				margin-bottom: 40px;
			}
			img {
				width: 100%;
				cursor: pointer;
			}

			&:nth-child(3) {
				margin-right: 5%;
			}
		}

		.title {
			font-size: 30px;
			letter-spacing: 10px;
			text-align: center;
			margin-bottom: 50px;
			margin-top: 60px;

			p {
				padding-left: 10px;
				box-sizing: border-box;

				&:last-child {
					padding-left: 0;
					font-size: 18px;
					letter-spacing: 0px;
				}
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				font-size: 24px;
				letter-spacing: 5px;
				p{
					&:last-child{
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
